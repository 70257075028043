import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { inject } from "@angular/core";
import { catchError, EMPTY, Observable, throwError } from "rxjs";

import { AUTH_HEADER } from "@common/constants";
import { AuthService } from "@services/auth.service";

export const AuthInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService)

  if (authService.isAuthenticated()) {
    request = addAuthenticationToken(request)
  }

  return next(request).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error?.status === 401) {
        authService.loginFromInvalidToken();
        return EMPTY
      } else {
        return throwError(() => error);
      }
    })
  );
}

const addAuthenticationToken = (request: HttpRequest<any>): HttpRequest<any> => {
  const authService = inject(AuthService)
  return request.clone({
    headers:
      request.headers
        .set(AUTH_HEADER, `Bearer ${authService.getToken()}`)
        .set('Accept', 'application/json')
  });
}

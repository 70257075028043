import { environment } from '@env/environment';
import { LOCAL_STORAGE_TOKEN } from 'src/app/common/tokens';

export const commonProviders = [
  { provide: Window, useValue: window },
  { provide: Document, useValue: document },
  { provide: LOCAL_STORAGE_TOKEN, useValue: window.localStorage }
];

export const GTMProviders = [
  { provide: 'googleTagManagerId', useValue: environment.googleTagManagerID },
  { provide: 'googleTagManagerAuth', useValue: environment.googleTagManagerAuth },
  { provide: 'googleTagManagerPreview', useValue: environment.googleTagManagerEnv },
]

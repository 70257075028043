<div
  [ngClass]=containerClasses class="animation-fadeIn">
  <div>
    <div>
      <img
        class="spinner animation-spin"
        [ngStyle]=spinnerStyles
        src="assets/loading-spinner.svg" />
    </div>
    <div class=text>{{text}}</div>
  </div>
</div>

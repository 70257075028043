<div class="dropdown-container">
    <div class="contact-details-container">
        <div class="contact-details">
            <p class="contact-header">Contact us</p>
            <p>
                Weekdays, 6 a.m. to 4:30 p.m., Pacific Time
            </p>
            <div class="contact-phone">
                <img src="assets/phone.svg" alt='Phone Number' />
                <a href="tel:{{contactPhone}}">{{contactPhone}}</a>
            </div>
        </div>
    </div>
</div>
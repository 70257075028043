import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { environment } from '@env/environment';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  currentYear = new Date().getFullYear();

  privacyPolicy = environment.legal.privacyPolicy;
  terms = environment.legal.terms;
  about = environment.legal.about;
  security = environment.legal.security;
  caPrivacyNotice = environment.legal.caPrivacyNotice;
  legalDisclosure = environment.legal.legalDisclosure;
  businessContinuity = environment.legal.businessContinuity
}

import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild, HostListener } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ContactComponent } from "../contact/contact.component";

@Component({
    selector: 'app-header',
    standalone: true,
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    imports: [CommonModule, RouterModule, ContactComponent]
})
export class HeaderComponent {

  @ViewChild('dropdownContainer')
  dropdownContainer: ElementRef;

  dropdownVisible: boolean = false;
  
  @HostListener('document:click', ['$event'])
  clickout(event: { target: ElementRef<any>; }) {
    if (this.dropdownContainer?.nativeElement) {
      if (this.dropdownContainer.nativeElement.contains(event.target)) {
        this.dropdownVisible = !this.dropdownVisible;
      } else {
        this.dropdownVisible = false;
      }
    }
  }
}

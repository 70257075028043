import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-loading-spinner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})

export class LoadingSpinnerComponent implements OnInit {
  @Input() fullScreen = false
  @Input() spinnerHeight = '5rem'
  @Input() text = 'Please wait...'
  readonly containerClasses: Record<string, any> = {}
  readonly spinnerStyles: Record<string, any> = {}

  ngOnInit() {
    if (this.fullScreen) {
      this.containerClasses['fixed'] = true
      this.containerClasses['spinner-wrapper'] = true
    }

    this.spinnerStyles['height'] = this.spinnerHeight
  }
}

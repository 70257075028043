<div class="footer-content">
  <div class="contact-info">
    <h3>CONTACT US</h3>
    <p>Weekdays, 6 a.m. to 4:30 p.m., Pacific Time</p>
    <h2>1-833-HiSwift</h2>
    <p>(1-833-447-9438)</p>
  </div>

  <div class="legal-links">
    <a [href]="about">About</a>
    |
    <a [href]="privacyPolicy">Privacy</a>
    |
    <a [href]="security">Security</a>
    |
    <a [href]="terms">Terms</a>
    |
    <a [href]="caPrivacyNotice">CA Privacy Notice</a>
    |
    <a [href]="legalDisclosure">Legal Disclosures</a>
    |
    <a [href]="businessContinuity">Business Continuity</a>
  </div>

  <div class="footer-text">
    Life insurance is issued by Symetra Life Insurance Company, 777 108th Ave NE, Suite 1200, Bellevue, WA 98004.
    Products not available in all U.S. states or any U.S. territory.
    <br /><br />
    Copyright © 2004 - {{ currentYear }} Symetra Life Insurance Company. All rights reserved. Symetra® is a registered
    service mark of Symetra Life Insurance Company.
  </div>
</div>

import { Routes } from '@angular/router';

import { canActivate as AuthGuard } from '@guards/auth-guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: 'getting-started/:offerCode',
    loadComponent: () =>
      import('./pages/getting-started/getting-started.component').then((m) => m.GettingStartedComponent),
  },
  {
    path: 'ssn-verify',
    loadComponent: () => import('./pages/ssn-verify/ssn-verify.component').then((m) => m.SsnVerifyComponent),
  },
  {
    path: 'account',
    loadComponent: () => import('./pages/account/account.component').then((m) => m.AccountComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'security',
    loadComponent: () => import('./pages/security/security.component').then((m) => m.SecurityComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'documents',
    loadComponent: () => import('./pages/documents/documents.component').then((m) => m.DocumentsComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'purchase',
    loadComponent: () => import('./pages/purchase/purchase.component').then((mod) => mod.PurchaseComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'confirmation',
    loadComponent: () => import('./pages/confirmation/confirmation.component').then((mod) => mod.ConfirmationComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'callback',
    loadComponent: () => import('./pages/callback/callback.component').then((mod) => mod.CallbackComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'rpi/:offerCode',
    loadComponent: () =>
      import('./pages/redirect-receiver/redirect-receiver.component').then((mod) => mod.RedirectReceiverComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'shared/:nanoid',
    loadComponent: () => import('./pages/shared/challenge/challenge.component').then((mod) => mod.ChallengeComponent),
  },
  {
    path: 'shared/:nanoid/document',
    loadComponent: () =>
      import('./pages/shared/document/document.component').then((mod) => mod.SharedDocumentComponent),
  },
];

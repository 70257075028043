import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})

export class LoadingSpinnerService {
  private readonly _state$ = new BehaviorSubject<boolean>(false)

  constructor() { }

  get state$() {
    return this._state$.asObservable()
  }

  hide() {
    this._state$.next(false)
  }

  show() {
    this._state$.next(true)
  }
}

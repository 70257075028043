<app-header></app-header>

<main>
  <router-outlet></router-outlet>
</main>

<app-footer></app-footer>

<app-loading-spinner
  *ngIf="spinner$ | async"
  [fullScreen]=true
>
</app-loading-spinner>

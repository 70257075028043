import { Injectable } from '@angular/core';

import { AUTH_TOKEN_KEY } from '@common/constants'
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private authToken: string = ''

  // Will need this service for the auth interceptor
  constructor(
    private window: Window
  ) { 
    this.getToken()
  }

  isAuthenticated(): boolean {
    return !!this.getToken();
  }

  setToken(token: string) {
    this.window.localStorage.setItem(AUTH_TOKEN_KEY, token);
    this.authToken = token;
  }

  getToken(): string {
    if (!this.authToken) {
      this.authToken = this.window.localStorage.getItem(AUTH_TOKEN_KEY) || '';
    }

    return this.authToken;
  }

  loginFromInvalidToken() {
    this.authToken = '';
    this.window.localStorage.removeItem(AUTH_TOKEN_KEY);
    this.window.location.href = `${environment.onboardingWebBaseUrl}/ssn-verify`;
  }
}

import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent, RouterOutlet } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { FooterComponent } from '@components/footer/footer.component';
import { HeaderComponent } from '@components/header/header.component';
import { LoadingSpinnerComponent } from '@components/loading-spinner/loading-spinner.component';
import { environment } from '@env/environment';
import { LoadingSpinnerService } from '@services/loading-spinner.service'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule, 
    RouterOutlet, 
    HeaderComponent, 
    LoadingSpinnerComponent, 
    FooterComponent
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  spinner$ = this.loadingSpinnerService.state$;

  constructor(
    private readonly loadingSpinnerService: LoadingSpinnerService,
    private readonly googleTagManagerService: GoogleTagManagerService,
    private readonly window: Window,
    private readonly router: Router
  ) {}

  ngOnInit() {
    if (typeof this.window !== 'undefined') {
      this.router.events.subscribe(this.handleNavigationEvent.bind(this));
    }
  }

  handleNavigationEvent(event: RouterEvent) {
    if (event instanceof NavigationEnd) {
      const { googleTagManagerID } = environment;
      
      if (googleTagManagerID !== '') {
        this.googleTagManagerService.pushTag({
          event: 'page',
          pageName: event.url
        });
      }
    }
  }
}

<div class="header-wrapper">
  <header class="page-header">
    <a [routerLink]="'/'">
      <img src="assets/SymetraSwiftterm-logo.svg" alt="Symetra Logo" />
    </a>

    <nav class="nav-links">
      <div #dropdownContainer>
        <a class="nav-link">
          Contact
        </a>
        <div *ngIf="dropdownVisible">
          <app-contact>
          </app-contact>
        </div>
      </div>
    </nav>
  </header>
</div>
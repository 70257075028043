import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";

import { environment } from "@env/environment";
import { AuthService } from "@services/auth.service";

export const canActivate: CanActivateFn = (): boolean => {
  const authService = inject(AuthService)
  
  if (!authService.isAuthenticated()) {
    window.location.href = `${environment.onboardingWebBaseUrl}/ssn-verify`;
  }
  return true
}
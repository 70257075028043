import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideEnvironmentNgxMask } from 'ngx-mask';

import { AuthInterceptor } from '@interceptors/auth.interceptor';

import { routes } from './app.routes';
import { commonProviders, GTMProviders } from './common/providers';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideEnvironmentNgxMask(),
    provideHttpClient(
      withInterceptors([
        AuthInterceptor
      ])
    ),
    provideAnimations(),
    ...commonProviders,
    ...GTMProviders
  ],
};
